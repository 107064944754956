import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import ExternalLink from "../components/blog/ExternalLink";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) =>{
    return <BlogPostWrapper>
        <StyledMainContent>
        <SEO
            title="Super Simple Plant Based Diet Shopping List"
            description="A plant based diet is proven to be one of the best things you can do to prevent diseases, manage your weight, and improve your overall energy."
            location={location}
            pageType="article"
        />
        <HeroSection>
            <BlogTitle>
                Super Simple Plant Based Diet Shopping List
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/plant-based-diet-shopping-list.jpg" alt="Super Simple Plant Based Diet Shopping List"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@nate_dumlao?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Nathan Dumlao</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/vegetarian?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            In our Western industrialized society, overly processed foods have become the norm. 
        </Paragraph>
        <Paragraph>
            Because of that, many people are looking for ways to make their diets healthier.
        </Paragraph>
         <Paragraph>
            One of the best ways to get back to basics with your eating habits is introducing a plant-based diet.
        </Paragraph>
        <Paragraph>
            Why?
        </Paragraph>
        <Paragraph>
            Because a whole food plant-based (WFPB) diet does not only focus on eliminating animal products, but on emphasizing unprocessed and low processed plant based foods.
        </Paragraph>
        <Paragraph>
            <ExternalLink to="https://apps.who.int/iris/bitstream/handle/10665/349086/WHO-EURO-2021-4007-43766-61591-eng.pdf?sequence=1&isAllowed=y">According to the WHO</ExternalLink>, a WFPB diet is highly recommended as part of a healthy lifestyle. 
        </Paragraph>
        <Paragraph>
            If you are willing to give it a go, but unsure where to start for your next trip to the grocery store, we have you covered with a plant based diet grocery list.
        </Paragraph>
        <Paragraph>
            <b>Key takeaways from this article:</b>
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>A plant based diet is one of the healthiest diet options you can go for (recommended by doctors!)</UnorderedListItem>
            <UnorderedListItem>No, you don't have to completely give up animal products to be on a plant based diet.</UnorderedListItem>
            <UnorderedListItem>You can drink a small to moderate amount of alcohol on a plant based diet.</UnorderedListItem>
            <UnorderedListItem>List of foods to focus on.</UnorderedListItem>
            <UnorderedListItem>List of foods to avoid on a plant based diet.</UnorderedListItem>
            <UnorderedListItem>If not balanced correctly, you may end up deficient in iron, vitamin B12 and/or calcium when eating mostly plant based foods.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Plant based shopping list printable
        </BlogHeading2>
        <Paragraph>
            Here's the entire list of plant based foods to print out and use whenever you like. 
        </Paragraph>
        <Paragraph>
            (Or you can just <InternalLink to="/#download-section">download a free app</InternalLink> and take your list with you on your phone wherever you go.)
        </Paragraph>
        <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/post-2022-16-plant-based-diet-shopping-list-printable.png" alt="Plant based diet shopping list printable"/>
        </ImageWithCaption>
        <BlogHeading2>
            What is a plant-based diet?
        </BlogHeading2>
        <Paragraph>
            A plant-based diet is, as the name suggests, a diet that incorporates plants as the foundation of every meal. That includes fruits, vegetables, nuts, seeds, legumes, whole grains, and herbs.
        </Paragraph>
        <Paragraph>
            When following a plant based diet, it is recommended to exclude or at least minimize animal products such as meat, eggs, and dairy.
        </Paragraph>
        <Paragraph>
            A plant-based diet is a more holistic approach to healthy eating because conventional dieting can be too restrictive, leading people to quit their diets too soon. That is why a plant-based diet can be a way to make more consistent and long-term healthy food choices.
        </Paragraph>
        <Paragraph>
            Not only is it good for your health, it is also good for the planet!
        </Paragraph>
        <Paragraph>
            <ExternalLink to="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5094759/">Studies have shown</ExternalLink> that if Western diets changed into more plant-based versions, water use would be reduced by 50%, and greenhouse gas emissions and land use would be reduced by 70%.
        </Paragraph>
        <BlogHeading2>
            Do doctors recommend a plant-based diet?
        </BlogHeading2>
        <Paragraph>
            Yes!
        </Paragraph>
        <Paragraph>
            A plant-based diet is highly recommended and scientifically proven to reduce the risk of a number of diseases.
        </Paragraph>
        <Paragraph>
            These diseases include:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><ExternalLink to="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4921549/"><b>Cardiovascular diseases</b></ExternalLink>. A plant based diet can reduce your risk of heart disease, high cholesterol levels, and high blood pressure.</UnorderedListItem>
            <UnorderedListItem><b>Some types of </b><ExternalLink to="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3565018/"><b>cancer</b></ExternalLink>. Following a plant-based diet can reduce your risk of certain types of cancer.</UnorderedListItem>
            <UnorderedListItem><ExternalLink to= "https://journals.plos.org/plosmedicine/article?id=10.1371/journal.pmed.1002039"><b>Diabetes</b></ExternalLink>. Eliminating sugar and refined carbohydrates greatly contributes to reducing insulin resistance.</UnorderedListItem>
            <UnorderedListItem><b>Obesity</b>. A plant-based diet is also good for <ExternalLink to="https://pubmed.ncbi.nlm.nih.gov/26138004/">weight</ExternalLink> management. It will help you lose weight in a healthy consistent way, improving your health long term and preventing further diseases.</UnorderedListItem>
            <UnorderedListItem><b>Alzheimer's disease and </b><ExternalLink to="https://www.bumc.bu.edu/busm/2021/02/25/plant-based-diets-improve-cardiac-function-cognitive-health/"><b>cognitive health.</b></ExternalLink></UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            If you'd like some more inspiration on science-backed healthy foods, check out our <InternalLink to="/healthy-pantry-staples/">healthy pantry staples!</InternalLink>
        </Paragraph>
        <BlogHeading2>
            Do I need to be a vegan or vegetarian to follow a plant-based diet?
        </BlogHeading2>
        <Paragraph>
            Technically, no.
        </Paragraph>
        <Paragraph>
            However, since a plant-based diet focuses on fresh food that comes from natural plant sources, most animal products are not allowed or should be reduced to create a balanced meal plan. 
        </Paragraph>
        <Paragraph>
            Since not all plant-based eaters are vegan or vegetarian, it is important to at least limit the amount of animal product foods consumed, such as:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Meat</UnorderedListItem>
            <UnorderedListItem>Fish</UnorderedListItem>
            <UnorderedListItem>Eggs</UnorderedListItem>
            <UnorderedListItem>Dairy</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            That said, you can still follow a plant-based diet while on a vegetarian, vegan, pescetarian, flexitarian, or a Mediterranean diet. They are all considered good plant based diet options.
        </Paragraph>
        <Paragraph>
            If you are on a vegan diet and are looking to make your life easier, we've got a <InternalLink to="/vegan-pantry-staples/">vegan grocery shopping list</InternalLink> already prepared!
        </Paragraph>
        <BlogHeading2>
            What should I eat on a plant-based diet?
        </BlogHeading2>
        <Paragraph>
            It's simple! More plants! 
        </Paragraph>
        <Paragraph>
            Here are some ideas:
        </Paragraph>
        <BlogHeading3>
            Vegetables
        </BlogHeading3>
        <Paragraph>
            Veggies should be your main source of food on this diet. That means finding creative ways to mix them up and prepare plant based meal plans.
        </Paragraph>
        <Paragraph>
            You could add fresh produce to any meal of the day, or - even better - make them the basis of every meal.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Broccoli</UnorderedListItem>
            <UnorderedListItem>Avocados</UnorderedListItem>
            <UnorderedListItem>Pepper</UnorderedListItem>
            <UnorderedListItem>Tomatoes</UnorderedListItem>
            <UnorderedListItem>Spinach</UnorderedListItem>
            <UnorderedListItem>Cauliflower</UnorderedListItem>
            <UnorderedListItem>Cucumber</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Starchy vegetables
        </BlogHeading3>
        <Paragraph>
            Fresh fruit should be your top choice for dessert on a plant-based diet! 
        </Paragraph>
        <Paragraph>
            Fruit is especially good for your health when packed with vitamin C and antioxidants. (And yes, frozen fruit counts.)
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Berries</UnorderedListItem>
            <UnorderedListItem>Apples</UnorderedListItem>
            <UnorderedListItem>Bananas</UnorderedListItem>
            <UnorderedListItem>Citrus fruits such as lemons, oranges, limes</UnorderedListItem>
            <UnorderedListItem>Mangoes</UnorderedListItem>
            <UnorderedListItem>Kiwi</UnorderedListItem>
            <UnorderedListItem>Pears</UnorderedListItem>
            <UnorderedListItem>Peaches</UnorderedListItem>
            <UnorderedListItem>Pineapple</UnorderedListItem>
            <UnorderedListItem>Dried fruit</UnorderedListItem>
        </UnorderedList>
        <ImageWithCaption>
            <StaticImage src="../assets/images/blogs/post-2022-16-plant-based-diet-shopping list-an-assortment-of-fruits.jpg" alt="An assortment of fruit including kiwis, grapes, citrus fruit, a pineapple, etc."/>
            <em>Photo by <ExternalLink to="https://unsplash.com/@juliazolotova?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Julia Zolotova</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/fruits?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading3>
            Plant based protein 
        </BlogHeading3>
        <Paragraph>
            Since you should at least be limiting the amount of animal foods you consume, it is important to look for some meat alternatives:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Nuts such as almonds, walnuts, peanuts, and cashews</UnorderedListItem>
            <UnorderedListItem>Beans and lentil protein such as black and kidney beans, soy, peas (canned beans are also okay)</UnorderedListItem>
            <UnorderedListItem>Seeds such as flaxseeds, chia seeds, sesame seeds</UnorderedListItem>
            <UnorderedListItem>Tofu</UnorderedListItem>
            <UnorderedListItem>Tempeh</UnorderedListItem>
            <UnorderedListItem>Vegan cheese</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Healthy fats
        </BlogHeading3>
        <Paragraph>
            Healthy fat foods are also very important for creating balanced meals and keeping you full throughout the day. 
        </Paragraph>
        <Paragraph>
            Here are some ideas:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Nut butter options such as peanut butter, almond butter, tahini</UnorderedListItem>
            <UnorderedListItem>Olive oil</UnorderedListItem>
            <UnorderedListItem>Coconut oil</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Whole grains
        </BlogHeading3>
        <Paragraph>
            If you are used to eating a lot of carbs, whole grains are your best option to substitute refined carbs for something filling you can include in your plant based grocery list.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Brown rice</UnorderedListItem>
            <UnorderedListItem>Quinoa</UnorderedListItem>
            <UnorderedListItem>Oats</UnorderedListItem>
            <UnorderedListItem>Whole grain baked goods</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Beverages
        </BlogHeading3>
        <Paragraph>
            When it comes to drinks it's also best to stick to the basics. 
        </Paragraph>
        <Paragraph>
            Watch out for added sugar in vegan milks though!
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Water</UnorderedListItem>
            <UnorderedListItem>Tea</UnorderedListItem>
            <UnorderedListItem>Coffee without sugar</UnorderedListItem>
            <UnorderedListItem>Dairy alternatives such as almond milk, soy milk, and other dairy free options</UnorderedListItem>
        </UnorderedList>
        <BlockQuote>
            <b>PRO TIP</b>: Add the items you want to a <InternalLink to="/#download-section">free shopping list app</InternalLink> on your phone so you don't forget to buy them on your next shopping trip!
        </BlockQuote>
        <BlogHeading2>
            What is not allowed on a plant-based diet?
        </BlogHeading2>
        <Paragraph>
            The most important rule to follow on a plant-based diet is to eat everything that looks like it grows from the earth, instead of foods that originated in a factory. 
        </Paragraph>
        <Paragraph>
            So if it looks like a product and has many additives and sugars on the label, it's best to avoid it.
        </Paragraph>
        <BlogHeading3>
            Sweets and sweetened beverages
        </BlogHeading3>
        <Paragraph>
            Most drinks that can be bought at the grocery store are unfortunately overly processed and contain a high dose of added sugar.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Soft drinks</UnorderedListItem>
            <UnorderedListItem>Fruit juices with added sugar</UnorderedListItem>
            <UnorderedListItem>Alcohol that is high in sugar</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Processed food
        </BlogHeading3>
        <Paragraph>
            No processed foods are allowed on a diet based on plant foods. That includes:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Chips</UnorderedListItem>
            <UnorderedListItem>Cookies</UnorderedListItem>
            <UnorderedListItem>Cereals</UnorderedListItem>
            <UnorderedListItem>Crackers</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Processed meats
        </BlogHeading3>
        <Paragraph>
            Even though some meat is tolerated on a plant based diet if you are flexitarian, processed meats are a strict no.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Bacon </UnorderedListItem>
            <UnorderedListItem>Sausage</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Refined grains
        </BlogHeading3>
        <Paragraph>
            Refined grains can be difficult to cut out because, for a lot of people, they make up most of their diet. However, they must go.
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>White wheat flour</UnorderedListItem>
            <UnorderedListItem>Pasta</UnorderedListItem>
            <UnorderedListItem>White bread</UnorderedListItem>
            <UnorderedListItem>White rice</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Fast food
        </BlogHeading3>
        <Paragraph>
            This should be obvious, but make sure you are cutting out as much fast food items from your diet as you can. 
        </Paragraph>
        <Paragraph>
            You can swap these with homemade plant based recipes: 
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Hamburgers</UnorderedListItem>
            <UnorderedListItem>Pizza</UnorderedListItem>
            <UnorderedListItem>Sandwiches</UnorderedListItem>
            <UnorderedListItem>Tacos</UnorderedListItem>
            <UnorderedListItem>Fried foods such as french fries</UnorderedListItem>
            <UnorderedListItem>Hot dogs</UnorderedListItem>
            <UnorderedListItem>Chicken nuggets</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            How do you start following a plant based diet?
        </BlogHeading2>
        <Paragraph>
            Whether your current eating habits are already quite healthy, or you are used to sugar and fast food, starting a new eating plan can be stressful.
        </Paragraph>
        <Paragraph>
            The primary benefit of plant based eating is that it is far less restrictive and more adaptable compared to other diets.
        </Paragraph>
        <Paragraph>
            Some of the ways you start introducing plant based food into your daily routine are:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Start slow.</b> All healthy improvements to your diet will be welcomed by your body. Don't beat yourself up if you sometimes choose a less healthy food option. Embrace the process instead. Remember, you're in it for the long run.</UnorderedListItem>
            <UnorderedListItem><b>Add veggies to every meal.</b> As simple as that, try to mix up your usual meals by adding vegetables while still keeping them tasty.</UnorderedListItem>
            <UnorderedListItem><b>Build a meal around a salad.</b> If you are not sure how to start, a good option is to start making a salad and incorporating your favorite plant based foods and spices.</UnorderedListItem>
            <UnorderedListItem><b>Create your own plant based version of a meal you love.</b> For example, if you are a burger person, try making one with whole grain bread, grilled veggies, and vegan cheese.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Can you drink alcohol on a plant-based diet?
        </BlogHeading2>
        <Paragraph>
            Technically, you can.
        </Paragraph>
        <Paragraph>
            Although, alcohol is not the healthiest beverage option if you are looking to improve your health.
        </Paragraph>
        <Paragraph>
            If you must, go for plant based alcohol options such as vegan wine.
        </Paragraph>
        <BlogHeading2>
            What bread can I eat on a plant-based diet?
        </BlogHeading2>
        <Paragraph>
            Avoid all white and refined grains. Yes, that includes regular white bread and pastries.
        </Paragraph>
        <Paragraph>
            Instead, go for whole grain bread that is full of seeds. 
        </Paragraph>
        <Paragraph>
            You can even bake your own bread at home, to make sure all the ingredients are spot-on.
        </Paragraph>
        <BlogHeading2>
            What are the negatives of a plant-based diet?
        </BlogHeading2>
        <Paragraph>
            Since on a plant based diet you would eliminate or minimize animal products, you could end up deficient in iron, vitamin B12 and/or calcium.
        </Paragraph>
        <Paragraph>
            Therefore, it would be wise to introduce B vitamins, especially vitamin B12 supplements to your diet, as it is one of the main concerns of any meat-free diet. Soy and nutritional yeast are also great options for B12.
        </Paragraph>
        <Paragraph>
            You can also improve your body's absorption abilities by adding more vitamin A, C, and D to your diet.
        </Paragraph>
        <BlogHeading2>
            Conclusion
        </BlogHeading2>
        <Paragraph>
            A plant based diet is scientifically proven to be one of the best things that you can do to prevent diseases, manage your weight, and improve your overall energy.
        </Paragraph>
        <Paragraph>
            Whether you eat meat or not, or have other food preferences, you can adapt our recommended plant based shopping list and incorporate the principles of a plant based diet into your own eating habits.
        </Paragraph>
        <Paragraph>
            Whatever you choose to do, you will quickly feel the major health benefits of a plant based diet.
        </Paragraph>
    </StyledMainContent>
    </BlogPostWrapper>
}

export default BlogPost;